import io from 'io' // external (cdn)
import { mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  render: () => null,

  data () {
    return {
      socket: false,
    }
  },

  computed: {
    ...mapState('Settings', ['settings']),

    ...mapFields('App', ['app.liveUserCount'])
  },

  watch: {
    liveUserCount: function (newVal, oldVal) {
      if (this.settings.debug && newVal > oldVal) {
        this.$helpers.newUserBeep()
      }
    },
  },

  methods: {
    ...mapMutations('Tickers', ['UPDATE_TICKER_DATA']),
  },

  mounted () {
    if (process.env.VUE_APP_SOCKET_URL) {
      this.socket = io(':3010')
    } else {
      this.socket = io(':3010', { secure: true })
    }
    
    this.socket.on('liveUserCount', (liveUserCount) => {
      this.liveUserCount = liveUserCount
    })

    this.socket.on('iexapi-data', (response) => {
      this.$helpers.debugEnabled() && console.log('iexapi response:', response)
      this.UPDATE_TICKER_DATA(response)
    })

    this.socket.on('iexapi-error', (error) => {
      this.$helpers.debugEnabled() && console.log('iexapi error occurred: ', error)
    })
  }
}