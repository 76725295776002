<template>
  <div>
    <Listeners />
    <Stats />
    <Optimizer />
    <Socket />
  </div>
</template>

<script>
import Listeners from '@/components/renderless/Listeners'
import Stats from '@/components/renderless/Stats'
import Optimizer from '@/components/renderless/Optimizer'
import Socket from '@/components/renderless/Socket'

export default {
  components: {
    Listeners,
    Stats,
    Optimizer,
    Socket
  }
}
</script>