<template>
  <div class="modal-stuff">
    <div class="app-details" @click="developersDevelopers">
      <span class="app-build">build: {{ app.build }}</span> 
      <span class="app-version">{{ settings.version }}</span>
    </div>

    <h3>Info / Settings</h3>
    <p>
      This is a build of <a href="https://github.com/shanehoban/Subreddit-Streamer" target="_blank">Subreddit Streamer</a> that polls all of <code>{{ settings.subreddit }}</code> for <code>{{ loadLimit }}</code> comments every <code>{{ loadTimeout/1000 }}</code> seconds with a peak throughput of <code>{{ stats.maxCommentsPerMinute }}</code> comments per minute.
    </p>
  
    <div class="other-settings">
      <h6>Settings</h6>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="showBotComments" v-model="showBotComments">
        <label class="form-check-label" for="showBotComments">Show Bot Comments</label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="showUsernames" v-model="showUsernames">
        <label class="form-check-label" for="showUsernames">Show Usernames &amp; Flair</label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="showTimestamps" v-model="showTimestamps">
        <label class="form-check-label" for="showTimestamps">Show Timestamps</label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="hideReplyIcons" v-model="hideReplyIcons">
        <label class="form-check-label" for="hideReplyIcons">
          Hide Reply Icons
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-reply-fill reply-icon small-reply-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L9.079 4.1A.716.716 0 0 0 8 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z"/>
          </svg>
        </label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="showPostTitles" v-model="showPostTitles">
        <label class="form-check-label" for="showPostTitles">Show Post Titles</label>
      </div>

      <div v-show="settings.subreddit === 'wallstreetbets' && app.screenWidth > 768" class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="disableEasterEggs" v-model="disableEasterEggs">
        <label class="form-check-label" for="disableEasterEggs">
          Disable Easter Eggs 
          <svg v-show="!disableEasterEggs" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-egg" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15a5 5 0 0 0 5-5c0-1.956-.69-4.286-1.742-6.12-.524-.913-1.112-1.658-1.704-2.164C8.956 1.206 8.428 1 8 1c-.428 0-.956.206-1.554.716-.592.506-1.18 1.251-1.704 2.164C3.69 5.714 3 8.044 3 10a5 5 0 0 0 5 5zm0 1a6 6 0 0 0 6-6c0-4.314-3-10-6-10S2 5.686 2 10a6 6 0 0 0 6 6z"/>
          </svg>
          <svg v-show="disableEasterEggs" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-egg-fried" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.665 6.113a1 1 0 0 1-.667-.977L13 5a4 4 0 0 0-6.483-3.136 1 1 0 0 1-.8.2 4 4 0 0 0-3.693 6.61 1 1 0 0 1 .2 1 4 4 0 0 0 6.67 4.087 1 1 0 0 1 1.262-.152 2.5 2.5 0 0 0 3.715-2.905 1 1 0 0 1 .341-1.113 2.001 2.001 0 0 0-.547-3.478zM14 5c0 .057 0 .113-.003.17a3.001 3.001 0 0 1 .822 5.216 3.5 3.5 0 0 1-5.201 4.065 5 5 0 0 1-8.336-5.109A5 5 0 0 1 5.896 1.08 5 5 0 0 1 14 5z"/>
            <circle cx="8" cy="8" r="3"/>
          </svg>
        </label>
      </div>

      <div class="form-group form-check" v-show="settings.subreddit === 'wallstreetbets' && app.screenWidth > 1024">
        <input type="checkbox" class="form-check-input" id="hideTickers" v-model="hideTickers">
        <label class="form-check-label" for="hideTickers">Hide Tickers</label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="hideCommentBoxes" v-model="hideCommentBoxes">
        <label class="form-check-label" for="hideCommentBoxes">Hide Reply Links/Login Text</label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="hideUpvoteBtns" v-model="hideUpvoteBtns">
        <label class="form-check-label" for="hideUpvoteBtns">Hide Upvote Button (if logged in)</label>
      </div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="disableAnimations" v-model="disableAnimations">
        <label class="form-check-label" for="disableAnimations">Disable Animations</label>
      </div>

      <div class="form-group form-check" v-show="settings.subreddit === 'wallstreetbets'">
        <input type="checkbox" class="form-check-input" id="hidePostLabels" v-model="hidePostLabels">
        <label class="form-check-label" for="hidePostLabels">Hide Post Labels</label>

        <small v-if="!hidePostLabels" class="text-muted setting-info fadeIn"><span class="color-circle green"></span> Green is todays daily thread, <span class="color-circle purple"></span> purple is tomorrows moves thread, <span class="color-circle blue"></span> blue can be any weekend thread</small>
      </div>

      <!-- <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="experimentalFeatures" v-model="experimentalFeatures">
        <label class="form-check-label" for="experimentalFeatures">enable experimental features 🌌</label>
      </div> -->
    </div>

    <p>
      <small class="text-muted">
        You have loaded approx. <code>{{ stats.commentsPerMinute }}</code> comments per minute, and have been loading comments for <code>{{ stats.timeSpentLoading }}</code> seconds. Loading more comments in <code>{{ stats.secondsToNextLoad }}</code> seconds.
      </small>
    </p>

    <p>
      <a href="https://github.com/shanehoban/Subreddit-Streamer" class="btn btn-primary" target="_blank">Subreddit Streamer on GitHub <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
      </svg></a>
    </p>

    <hr>

    <div class="btn-list modal-menu">
      <span>
        <a class="btn btn-dark" :href="'mailto:' + config.emailAddress" target="_blank" :aria-label="config.emailAddress" data-balloon-pos="up"> Contact </a>
        <button class="reset-settings-btn btn btn-danger" @click="resetSettings">Reset Settings</button> 
      </span>
      <span>
        <button v-if="debug" @click="resetAllStates" class="btn btn-outline-info">Reset Vuex</button>
        <button v-if="debug" @click="toggleDebug" class="btn btn-outline-info">Debug Off</button>
        <button @click="showSettingsModal = false" class="btn btn-primary">Close</button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState, mapMutations } from 'vuex'

export default {  
  data () {
    return {
      mobileTimeout: false,
      current: 0,
    }
  },

  computed: {
    ...mapState('App', ['app']),
    ...mapState('Settings', ['settings']),
    ...mapState('Config', ['config']),
    ...mapState('Stats', ['stats']),

    ...mapFields('App', [
      'app.modals.showSettingsModal',
    ]),

    ...mapFields('Config', [
      'config.loadTimeout',
      'config.loadLimit',
    ]),

    ...mapFields('Settings', [
      'settings.debug',
      'settings.subreddit',
      'settings.experimentalFeatures',
      'settings.showBotComments',
      'settings.showUsernames',
      'settings.showTimestamps',
      'settings.disableAnimations',
      'settings.showPostTitles',
      'settings.hideTickers',
      'settings.hideCommentBoxes',
      'settings.hideUpvoteBtns',

      'settings.hideReplyIcons',
      'settings.disableEasterEggs',
      'settings.hidePostLabels',
    ]),
  },

  methods: {
    ...mapMutations('App', ['RESET_APP']),
    ...mapMutations('Auth', ['RESET_AUTH']),
    ...mapMutations('Comments', ['RESET_COMMENTS']),
    ...mapMutations('Config', ['RESET_CONFIG']),
    ...mapMutations('Settings', ['RESET_SETTINGS']),
    ...mapMutations('Stats', ['RESET_STATS']),
    ...mapMutations('Threads', ['RESET_THREADS']),
    ...mapMutations('Tickers', ['RESET_TICKERS']),

    resetSettings () {
      if (this.settings.subreddit !== 'wallstreetbets') {
        this.showSettingsModal = false
      }

      this.RESET_SETTINGS()

      if (this.subreddit !== this.$route.params.subreddit) {
        this.$router.replace('/r/' + this.subreddit)
      }

      this.$helpers.toast('Settings Reset', 'success')
    },

    resetAllStates () {
      this.RESET_APP()
      this.RESET_AUTH()
      this.RESET_COMMENTS()
      this.RESET_CONFIG()
      this.RESET_SETTINGS()
      this.RESET_STATS()
      this.RESET_THREADS()
      this.RESET_TICKERS()
      location.reload()
    },

    toggleDebug () {
      this.debug = !this.debug
      this.$helpers.toast('Debug ' + ((this.debug) ? 'On' : 'Off'))
    },

    debugClickChecker () {
      clearTimeout(this.mobileTimeout)
      this.current = 0
      this.mobileTimeout = false
    },

    developersDevelopers () {
      this.current++
      
      if (this.current > 4) {
        this.current = 0
        this.toggleDebug()
      }

      if (!this.mobileTimeout) {
        this.mobileTimeout = setTimeout(this.debugClickChecker, 2500)
      }
    }
  },
}
</script>

<style lang="scss" scoped>


  .app-details {
    font-family: $code-font;
    font-size: 12px;
    opacity: 0.3;
    text-align: center;
    -webkit-user-select: none;  
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0 0 5px 5px;
    cursor: pointer;

    .light-theme & {
      opacity: 0.65;
    }

    .app-build {
      margin-right: 5px;
    }
  }

  .other-settings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: var(--bg-light);
    border-radius: 5px;
    margin-bottom: 5px;

    @include w768 {
      padding: 15px;
    }

    .form-group {
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      @include w768 {
        flex-basis: 50%;
      }

      input {
        margin-right: 7px;
      }

      span {
        flex-basis: 100%;
      }
    }

    h6 {
      margin-bottom: 10px;
      flex-basis: 100%;
    }

    .small-reply-icon {
      font-size: 10px;
      line-height: 1;
      vertical-align: middle;
      opacity: 0.5;
      margin-left: 2px;
    }

    .form-group:last-of-type {
      margin-bottom: 0;
    }

    .setting-info {
      flex-basis: 100%;
      display: inline-block;
      padding-right: 5px;
      margin-top: 5px;
    }
  }

</style>