<template>
  <div class="floaty-nav">
    <div class="newest-text" @click="scrollToBottom">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left sideways-arrow" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
      </svg>
      new<span class="hide-on-mobile">est</span>
    </div>

    <div class="settings-menu">

      <button v-if="settings.debug" @click="showStatsModal = true" class="btn" aria-label="Stats" 
        :data-balloon-pos="balloonPos">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-clipboard-data" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
            <path fill-rule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
            <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z"/>
          </svg>
      </button>

      <button v-show="!app.serversResponding" class="btn server-btn" aria-label="Reddit servers are not responding" :data-balloon-pos="balloonPos">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-hdd-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M0 10a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-1zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
          <path d="M.91 7.204A2.993 2.993 0 0 1 2 7h12c.384 0 .752.072 1.09.204l-1.867-3.422A1.5 1.5 0 0 0 11.906 3H4.094a1.5 1.5 0 0 0-1.317.782L.91 7.204z"/>
        </svg>
      </button>

      <button v-show="!app.online" class="btn offline-btn" aria-label="You seem to be offline" :data-balloon-pos="balloonPos">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-wifi-off" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404l.785-.785c.63.24 1.228.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.934-.933a6.454 6.454 0 0 1 2.012.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905l.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .048.737.518.518 0 0 1-.668.05 11.496 11.496 0 0 0-1.812-1.07zM9.02 11.78c.238.14.236.464.04.66l-.706.706a.5.5 0 0 1-.708 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.373 0 .722.102 1.02.28zm4.355-9.905a.53.53 0 1 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
        </svg>
      </button>

      <button  v-if="filterEnabled" @click="disableFilter" class="btn remove-filter-btn" aria-label="Remove Filter" :data-balloon-pos="balloonPos">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>

      <a v-if="settings.subreddit === 'wallstreetbets'" class="btn" href="https://www.reddit.com/r/wallstreetbets/?ref=meme-stonks.com" target="_blank" aria-label="/r/wsb" 
        :data-balloon-pos="balloonPos">
          <img width="18" :src="require('@/assets/img/wsb-head.png')">
      </a>

      <button v-if="comments.commentCount > 0" class="btn" @click="clearComments" aria-label="Clear Comments" :data-balloon-pos="balloonPos">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
      </button>

      <button @click="showSubredditModal = true" class="btn" aria-label="Change Subreddit" 
        :data-balloon-pos="balloonPos">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-signpost" viewBox="0 0 16 16">
          <path d="M7 1.414V4h2V1.414a1 1 0 0 0-2 0z"/>
          <path fill-rule="evenodd" d="M12.532 5H2v4h10.532l1.666-2-1.666-2zM2 4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h10.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H2z"/>
          <path d="M7 10h2v6H7v-6z"/>
        </svg>
      </button>

      <button @click="changeTheme" :class="'btn theme-btn ' + settings.theme" aria-label="Change Theme"
        :data-balloon-pos="balloonPos">
        
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high light-icon" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill dark-icon" viewBox="0 0 16 16">
          <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"/>
          <path fill-rule="evenodd" d="M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
        </svg>
      </button>
      
      <a class="btn" :href="'mailto:' + config.emailAddress" target="_blank" :aria-label="config.emailAddress" 
        :data-balloon-pos="balloonPos">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
          </svg>
      </a>

      <button @click="scrollToBottom" class="btn scroll-bottom-btn" aria-label="Scroll to Bottom" :data-balloon-pos="balloonPos">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
        </svg>
      </button>

            <!-- login btn -->
      <a class="btn reddit-btn" :href="auth.refresh_token === false && $helpers.getAuthURL()" :aria-label="auth.refresh_token ? 'You are logged in' : 'Login with Reddit'" :data-balloon-pos="balloonPos" :disabled="auth.refresh_token !== false">
        <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 512 512" role="img" class="svg-inline--fa fa-reddit-alien fa-w-16" data-icon="reddit-alien" data-prefix="fab" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path d="M440.3 203.5c-15 0-28.2 6.2-37.9 15.9-35.7-24.7-83.8-40.6-137.1-42.3L293 52.3l88.2 19.8c0 21.6 17.6 39.2 39.2 39.2 22 0 39.7-18.1 39.7-39.7s-17.6-39.7-39.7-39.7c-15.4 0-28.7 9.3-35.3 22l-97.4-21.6c-4.9-1.3-9.7 2.2-11 7.1L246.3 177c-52.9 2.2-100.5 18.1-136.3 42.8-9.7-10.1-23.4-16.3-38.4-16.3-55.6 0-73.8 74.6-22.9 100.1-1.8 7.9-2.6 16.3-2.6 24.7 0 83.8 94.4 151.7 210.3 151.7 116.4 0 210.8-67.9 210.8-151.7 0-8.4-.9-17.2-3.1-25.1 49.9-25.6 31.5-99.7-23.8-99.7zM129.4 308.9c0-22 17.6-39.7 39.7-39.7 21.6 0 39.2 17.6 39.2 39.7 0 21.6-17.6 39.2-39.2 39.2-22 .1-39.7-17.6-39.7-39.2zm214.3 93.5c-36.4 36.4-139.1 36.4-175.5 0-4-3.5-4-9.7 0-13.7 3.5-3.5 9.7-3.5 13.2 0 27.8 28.5 120 29 149 0 3.5-3.5 9.7-3.5 13.2 0 4.1 4 4.1 10.2.1 13.7zm-.8-54.2c-21.6 0-39.2-17.6-39.2-39.2 0-22 17.6-39.7 39.2-39.7 22 0 39.7 17.6 39.7 39.7-.1 21.5-17.7 39.2-39.7 39.2z" fill="currentColor"></path></svg>
      </a>

      <button @click="showSettingsModal = true" class="btn" aria-label="Settings" :data-balloon-pos="balloonPos">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"/>
          <path fill-rule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapState('App', ['app']),
    ...mapState('Auth', ['auth']),
    ...mapState('Settings', ['settings']),
    ...mapState('Comments', ['comments']),
    ...mapState('Config', ['config']),

    ...mapFields('App', [
      'app.commentsCleared',
      'app.modals.showSettingsModal',
      'app.modals.showSubredditModal',
      'app.modals.showStatsModal',
    ]),

    ...mapFields('Settings', [
      'settings.commentFilter.filterEnabled'
    ]),

    balloonPos () {
      return this.app.screenWidth > 768 ? 'left' : 'up'
    },
  },

  methods: {
    ...mapMutations('Settings', ['UPDATE_SETTING']),
    ...mapMutations('Comments', ['RESET_COMMENTS']),

    disableFilter () {
      this.filterEnabled = false
      this.$helpers.toast('Filter Disabled')
    },

    clearComments () {
      this.RESET_COMMENTS()
      this.commentsCleared = true
      this.$helpers.toast('Comments Cleared')
    },

    scrollToBottom () {
      this.$helpers.scrollToBottom()
    },

    changeTheme () {
      this.UPDATE_SETTING({
        key: 'theme', 
        value: (this.settings.theme === 'dark-theme') ? 'light-theme' : 'dark-theme'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .floaty-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 58px;
    background: var(--bg-dark);
    padding: 4px 20px;

    @include w450 {
      padding: 4px 27px;
    }

    @include w768 {
      position: relative;
      justify-content: space-between;
      margin-top: 0;
      padding: 0;
      border: 0;
      background: unset;
    }
  }

  .newest-text {
    display: none;
    letter-spacing: 0.5px;
    color: #858585;
    transition: all 0.25s ease;
    line-height: 0;
    position: fixed;
    transform: rotate(270deg);
    margin-left: -60px;
    bottom: 115px;
    cursor: pointer;
    -webkit-user-select: none;  
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      color: #585858;
    }

    @include w768 {
      display: block;
    }
  }

  .settings-menu {
    display: flex;
    flex-direction: row;

    .btn {
      color: inherit;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
      padding: 3px 7px;
      
      @include w450 {
        margin: 0 5px;
        padding: 3px 10px;
      }

      @include w768 {
        margin: 5px 0 0 0;
        padding: 7.5px;
      }
    }

    .offline-btn,
    .server-btn {
      cursor: default;
      animation-direction: alternate;
    }

    .offline-btn {
      color: $red;
      animation: offlinePulse 0.75s infinite;
    }

    .server-btn {
      color: $orange;
      animation: unresponsivePulse 0.75s infinite;
    }

    .btn.reddit-btn {
      color: $orange;

      &[disabled] {
        svg {
          color: $purple;
          color: $orange;
        }
      }
    }

    .remove-filter-btn {
      color: $red;
    }

    .theme-btn {
      .light-icon,
      .dark-icon {
        display: none;
      }

      &.dark-theme .light-icon { display: inline-block; }
      &.light-theme .dark-icon { display: inline-block; }
    }

    .scroll-bottom-btn {
      display: inline-flex;

      @include w768 {
        display: none;
      }
    }

    @include w768 {
      position: fixed;
      bottom: 80px;
      margin-left: 660px;
      flex-direction: column;
    }
  }

  @keyframes unresponsivePulse {
    0% {
      color: inherit;
    }
    100% {
      color: $orange;
    }
  }

  @keyframes offlinePulse {
    0% {
      color: inherit;
    }
    100% {
      color: $red;
    }
  }
</style>