<template>
  <div :class="'ticker-box fadeIn' + (settings.commentFilter.filterEnabled ? ' filter-enabled' : '' )">
    <div class="ticker-content">
      <div class="hot-memes">
        <div v-for="ticker in tickers.tickers" :key="ticker.id" class="ticker-ticker">
          <span class="ticker-symbol">{{ ticker.symbol }}: </span>
          <span class="ticker-price">${{ tickerPrice(ticker.latestPrice) }} </span>
          <span :class="'ticker-change ' + tickerDirection(ticker.changePercent)">{{ tickerChange(ticker.changePercent) }}%</span>
        </div>
      </div>

      <div class="ticker-updated text-muted">
        <span class="ticker-date"> {{ tickerDate }} </span>
        <span class="ticker-time"> {{ tickerTime }} </span>
      </div>

      <div class="ticker-attribution">
        <a href="https://iexcloud.io?ref=meme-stonks.com" target="_blank" aria-label="Data from iex cloud (15 min delayed)" data-balloon-pos="left">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
            <circle cx="8" cy="4.5" r="1"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Tickers', ['tickers']),
    ...mapState('Settings', ['settings']),

    tickerDate () {
      let date = new Date(this.tickers.lastUpdated).toLocaleDateString()
      return date || ''
    },

    tickerTime () {
      let date = new Date(this.tickers.lastUpdated).toLocaleTimeString()
      return date || ''
    }
  },

  methods: {
    tickerPrice (price) {
      return Number.parseFloat(price).toFixed(2) || 0
    },

    tickerDirection (changePct) {
      let direction
      let changePercent = Number.parseFloat(changePct)*100
      let decimalPercent = changePercent.toFixed(2) // could be "-0.00"
      direction = changePercent > 0 ? 'bull' : 'bear'
      direction = (decimalPercent === '-0.00' || decimalPercent === '0.00') ? 'flat' : direction
      return direction
    },

    tickerChange (changePct) {
      let change
      let changePercent = Number.parseFloat(changePct)*100
      let decimalPercent = changePercent.toFixed(2) // could be "-0.00"
      change = (changePercent > 0 ? '+' : '') + decimalPercent // the minus comes with it
      change = (decimalPercent === '-0.00') ? '±0.00' : change
      return change
    },
  },
}
</script>

<style lang="scss" scoped>
  .ticker-box {
    display: none;
    position: fixed;
    top: 10px;
    right: 10px;
    text-align: right;
    font-size: 13px;
    user-select: none;
    font-family: $code-font;
    transition: background 0.25s ease;

    @include w1024 {
      display: block;
    }

    &.filter-enabled {
      z-index: 10;
      padding: 8px;
      border-radius: 5px;
      background-color: var(--bg-darker);
      
      &:hover {
        background: var(--bg-darkest);
      }

      .light-theme & {
        background-color: var(--bg-lighter);

        &:hover {
          background-color: var(--bg-lightest);
        }
      }
    }
  }

  .ticker-content {
    position: relative;
  }

  .hot-memes {
    text-transform: lowercase;
  }

  .ticker-symbol {
    opacity: 0.5;
  }

  .ticker-change {
    font-size: 11px;

    &.bull { color: $green; }
    &.bear { color: $red; }
    &.flat { opacity: 0.35; }
  }

  .ticker-updated {
    display: inline-block;
    margin-top: 5px;
    font-size: 11px;
  }

  .ticker-attribution {
    font-weight: bold;
    padding: 6px 0px 5px 10px;
    font-size: 14px;
    width: auto;

    .filter-enabled & {
      position: absolute;
      right: 0;
      bottom: -40px;
    }

    a {
      color: inherit;
      opacity: 0.4;
      transition: opacity 0.25s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
</style>