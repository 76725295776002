/* Stats.store.js */

import { getField, updateField } from 'vuex-map-fields';

const getDefaultThreadsState = () => {
  return {
    tickers: {
      error: false,
      tickers: [],
      lastUpdated: false
    }
  }
}

// State object
const state = getDefaultThreadsState()

// Getter functions
const getters = {
  getField, // for v-models
}

// Actions
const actions = {}

// Mutations
const mutations = {
  updateField, // for v-models

  // {
  //   "message": "200 ok baby",
  //   "tickers": ["pltr", "tsla", "nio", "spy"],
  //   "data": [29.93, 663.07, 45.19, 372.48],
  //   "lastUpdated": 1607377181569
  // }
  UPDATE_TICKER_DATA (state, payload) {
    if (payload.length > 0) {
      state.tickers.error = false
      state.tickers.tickers = payload
      state.tickers.lastUpdated = payload[0].latestUpdate
    } else {
      state.tickers.error = true
    }
  },

  RESET_TICKERS (state) {
    Object.assign(state, getDefaultThreadsState())
  },
}

export default {
  // Make sure all modules in the store are namespaced (so we can access them via eg. User/login)
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
