<template>
  <div class="modal-stuff text-center">
    <h4>New Version Released</h4>

    <p>
      You will need to reset your settings in order to use it.
    </p>
    <p>
      If you have the site open in another tab, please also close that
    </p>

    <div class="modal-buttons">
      <button class="btn btn-success" @click="reloadApp">Update Now</button>
    </div>
    
    <div class="whats-new">
      <h6>Recent Updates:</h6>

      <div class="news-list">
        <div class="news">You can now browse with both <code>/subreddit/the_subreddit</code>, <code>/reddit/the_subreddit</code>, and <code>/r/the_subreddit</code></div>
      </div>

      <div class="old-news">
        <h6>Previous Updates</h6>
        <div class="news-list">
          <div class="news">Increased the max comment load limit</div>
          <div class="news">You can now use the url to change subreddit</div>
          <div class="news">Text and font updates</div>
          <div class="news">Added ability to login through reddit</div>
          <div class="news">Added ability to reply to comments</div>
          <div class="news">Added ability to comment directly to threads</div>
          <div class="news">Added ability upvote comments</div>
          <div class="news">Overhauled the themes and colour schemes</div>

          <div class="news"> Added tickers for hot memes (desktop) </div>
          <div class="news"> Added clear comment button </div>
          <div class="news"> Optimized sticky scroll for user experience </div>
          <div class="news"> Implimented an optimizer to reduce load on reddit servers </div>
          <div class="news"> Added the ability to change subreddit </div>
          <div class="news"> Added post titles </div>
          <div class="news"> Added timestamps </div>
          <div class="news"> Added comment authors &amp; flair </div>
          <div class="news"> Added theme changing (dark/light) </div>
          <div class="news"> Added post labels </div>
        </div>
      </div>

      <div class="modal-buttons">
        <button class="btn btn-success" @click="reloadApp">Update Now</button>
        <a class="btn btn-purple" :href="'mailto:' + config.emailAddress" target="_blank">Contact</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
    ...mapState('Config', ['config']),

    ...mapFields('App', [
      'app.modals.showUpdateModal',
    ]),

  },

  methods: {
    // the only persistent store is settings, and auth
    ...mapMutations('Auth', ['RESET_AUTH']),
    ...mapMutations('Settings', ['RESET_SETTINGS']),


    reloadApp() {
      this.RESET_AUTH()
      this.RESET_SETTINGS()
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
  .modal-stuff {
    max-width: 500px;
    padding: 30px;
  }

  .modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 0;
  }

  .whats-new {
    opacity: 0.8;
  }

  .news-list {
    font-size: 13px;

    .news {
      margin-bottom: 5px;
    }
  }

  .old-news {
    opacity: 0.6;
    margin-top: 20px;
  }
</style>